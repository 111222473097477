import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const Policy = () => {
  return (
    <div className="bg-custom-bg min-h-screen px-auto">
      {/* <div className="container"> */}
      <div className="m-4">
        <img
          src="images\END2END LOGO.png"
          alt="End2End Logo"
          className="w-[100px] h-[120px] md:w-[100px] md:h-auto lg:w-[120px] lg:h-auto"
        />
      </div>
      {/* <div className="bg-custom-bg shadow-md rounded-lg overflow-hidden"> */}
      {/* <div className="p-6"> */}
      <h1 className="text-center text-[40px] font-bold mt-[25px] mb-6">
        Disclaimer & Privacy Policy
      </h1>

      <div className="container mx-auto sm:p-[10px] lg:p-[40px] text-[14px] text-black font-roboto">
        <h2 className="text-xl font-bold text-black">Disclaimer</h2>
        <p className="text-[23px] font-roboto p-[20px] text-black text-justify">
          This website belongs to End 2 End Home Solution, a RERA registered
          company. We serve as trusted Channel Partners, collaborating with MICL
          Group to promote their real estate projects. Please note that our
          micro website is used for information purposes only and does not
          constitute an offer to avail of any service. Prices & discounts
          mentioned are subject to change without notice. The properties
          mentioned are subject to availability. All images are for
          representation purposes only. End 2 End Home Solution is not liable
          for any alterations in value or content as all the information is
          received from the builder office. By submitting your details on our
          micro website, you provide us with your consent to contact you
          regarding the marketed products, promotions, and offers or updates via
          phone calls, SMS, Email and/or What’s App, superseding any DNC/NDNC
          registration. All rights reserved, unauthorized use of this website
          content may violate laws.
        </p>

        <h2 className="text-xl font-bold mb-4">Website Privacy Policy</h2>
        <ul className="list-disc space-y-4 font-roboto text-[23px] p-[10px] text-black text-justify">
          <li>
            <a
              href="https://aaradhya-avaan-tardeo.southmumbai.co.in/"
              className="text-blue-500"
            >
              aaradhya-avaan-tardeo.southmumbai.co.in
            </a>{" "}
            recognises the importance of maintaining your privacy. We value your
            privacy and appreciate your trust in us.
          </li>
          <li>
            This Policy describes how we treat user information we collect on{" "}
            <a
              href="https://aaradhya-avaan-tardeo.southmumbai.co.in/"
              className="text-blue-500"
            >
              aaradhya-avaan-tardeo.southmumbai.co.in
            </a>{" "}
            and other offline sources.
          </li>
          <li>
            This Privacy Policy applies to current and former visitors to our
            website and to our online customers.
          </li>
          <li>
            By visiting and/or using our website, you agree to this Privacy
            Policy.
          </li>
        </ul>

        <h2 className="text-xl font-bold mb-4">Information we collect:</h2>
        <ul className="list-disc space-y-4 font-roboto text-[23px] p-[10px] text-black text-justify">
          <li>
            Contact information: We might collect your name, email, mobile
            number, phone number, street, city, state, pincode, country, and IP
            address.
          </li>
        </ul>

        <ul className="list-disc space-y-4 font-roboto text-[23px] p-[10px] text-black text-justify">
          <li>
            Payment and billing information: We NEVER collect your billing
            information like credit/debit card number or credit/debit card
            expiry date or other details pertaining to your credit/debit card on
            our website. Credit/debit card or any other billing information will
            be obtained and processed offline only at the time of purchasing the
            properties.
          </li>
        </ul>

        <ul className="list-disc space-y-4 font-roboto text-[23px] p-[10px] text-black text-justify">
          <li>
            Information you post: We collect information you post in a public
            space on our website or on a third-party social media site.
          </li>
          <li>
            Demographic information: We may collect demographic information
            about you, events you like, events you intend to participate in, you
            buy, or any other information through social media channels provided
            by you during the use of social media channels. We might collect
            this as a part of a survey also.
          </li>
          <li>
            Other information: If you use our website, we may collect
            information about your IP address and the browser you're using. We
            might look at what site you came from, duration of time spent on our
            website, pages accessed, or what site you visit when you leave us.
            We might also collect the type of mobile device you are using, or
            the version of the operating system your computer or device is
            running.
          </li>
          <li>We collect information in different ways:</li>
          <li>
            We collect information directly from you: We collect information
            directly from you when you register for an event or buy tickets. We
            also collect information if you post a comment on our websites or
            ask us a question through phone or email.
          </li>
          <li>
            We collect information from you passively: We use tracking tools
            like Google Analytics, Google Webmaster, browser cookies, and web
            beacons for collecting information about your usage of our website.
          </li>
          <li>
            We get information about you from third parties: For example, if you
            use an integrated social media feature on our websites. The
            third-party social media site will give us certain information about
            you. This could include your name and email address.
          </li>
          <li>
            Use of your personal information: We use information to contact you:
            We might use the information you provide to contact you for
            confirmation of a purchase on our website or for other promotional
            purposes.
          </li>
          <li>
            We use information to respond to your requests or questions: We
            might use your information to confirm your registration for an event
            or contest.
          </li>
          <li>
            We use information to improve our products and services: We might
            use your information to customize your experience with us. This
            could include displaying content based upon your preferences.
          </li>
          <li>
            We use information to look at site trends and customer interests: We
            may use your information to make our website and products better. We
            may combine information we get from you with information about you
            we get from third parties.
          </li>
          <li>
            We use information for security purposes: We may use information to
            protect our company, our customers, or our websites.
          </li>
          <li>
            We use information for marketing purposes: We might send you
            information about special promotions or offers. We might also tell
            you about new features or products. These might be our own offers or
            products, or third-party offers or products we think you might find
            interesting.
          </li>
        </ul>

        <h2 className="text-xl font-bold mb-4">
          We use information as otherwise permitted by law.
        </h2>
        <ul className="list-disc space-y-4 font-roboto text-[23px] p-[10px] text-black text-justify">
          <p className="font-semibold">
            Sharing of information with third-parties:
          </p>
          <li>
            We will share information with third parties who perform services on
            our behalf: We share information with vendors who help us manage our
            online registration. Some vendors may be located outside of India.
          </li>
          <li>
            We will share information with the event organizers: We share your
            information with event organizers and other parties responsible for
            fulfilling the purchase obligation. The event organizers and other
            parties may use the information we give them as described in their
            privacy policies.
          </li>
          <li>
            We may share information with any successor to all or part of our
            business: For example, if part of our business is sold, we may give
            our customer list as part of that transaction.
          </li>
          <li>
            We may share your information for reasons not described in this
            policy: We will tell you before we do this.
          </li>
        </ul>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}

      <div className="foot_top bg-gray-800 text-white py-8 text-center">
        <div className="container mx-auto px-4">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="foot_left">
                <h1 className="text-2xl md:text-4xl font-bold">
                  AARADHYA AVAAN
                </h1>
                <p className="mt-4 text-base md:text-lg text-justify">
                  Disclaimer: This website is meant only for information
                  purposes. It should not be considered / claimed as an official
                  site. This website belongs to the authorized channel partner
                  of MICL Group. The content provided on this website is for
                  information purposes only and does not constitute an offer to
                  avail any service. The prices mentioned are subject to change
                  without prior notice, and the availability of properties
                  mentioned is not guaranteed. The images displayed on the
                  website are for representation purposes only and may not
                  reflect the actual properties accurately. We may share data
                  with Real Estate Regulatory Authority (RERA) registered
                  brokers/companies for further processing as required. We may
                  also send updates and information to the mobile number or
                  email ID registered with us. All rights reserved. The content,
                  design, and information on this website are protected by
                  copyright and other intellectual property rights. Any
                  unauthorized use or reproduction of the content may violate
                  applicable laws.
                </p>
              </div>
              <h3 className="text-center justify" style={{ color: "#fff" }}>
                MahaRERA registration number: P51900048675
                maharera.mahaonline.gov.in
              </h3>

              <h3 className="text-center justify" style={{ color: "#fff" }}>
                MahaRERA registration number: A52000032416
                maharera.mahaonline.gov.in
              </h3>
            </div>
          </div>
        </div>
      </div>

      <a
        href="https://api.whatsapp.com/send?phone=9326959938&text=I am interested in AARADHYA AVAAN TARDEO, MUMBAI project and want more information about this project."
        className="floating-button"
        target="_blank"
      >
        <div className="floating-btn">
          <FaWhatsapp size={30} />
        </div>
      </a>
    </div>
  );
};

export default Policy;
